$primary: #2a61af;
body{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
.content{
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    header{
        position: fixed;
        display: flex;
        width: 100vw;
        height: 10vh;
        background-color: $primary;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        .dark{
            @media screen and (max-width: 1130px) {
            position: fixed;
            width: 100vw;
            height: 100vh;
            opacity: 0;
            left: 0;
            top: 0;
            transform: translateX(-100vw);
            transition: transform 0s 0.2s ease-in-out, opacity 0.2s ease-in-out;
            }
        }
        .dark--active{
            @media screen and (max-width: 1130px) {
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 1;
            position: fixed;
            width: 100vw;
            height: 100vh;
            transition: transform 0.1s ease-in-out, opacity 0.2s 0.3s ease-in-out;
            transform: translateX(0);
            }
        }
        .hamburger{
            display: none;
            @media screen and (max-width: 1130px) {
                display: block;
                cursor: pointer;
                border: 0;
                position: fixed;
                right: 0;
                margin: 0 3vw 0 0;
                background-color: $primary;
            }
        }
        a{
            img{
                margin: 0 0 0 2vw;
                height: 5vh;
            }
        }
        .navigation{
            font-size: 2vh;
            margin: 0 1vw 0 0;

            .navigation__list{
                display: flex;
                list-style: none;
                cursor: pointer;
                transition: 1;

                .navigation__item{
                    a{
                        color: white;
                        text-decoration: none;
                        position: relative;
                        padding: 0 0.5vw .2vw 0.7vw;
                    }
                    a:hover{
                        color: lightblue;
                    }
                    a::after{
                        transform: scale(0);
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 0.1em;
                        background-color: lightblue;
                        transition: opacity .5s, transform .5s;
                    }
                    a:hover::after, a:focus::after{
                        transform: scale(1);
                    }
                }
            }
        }
    }
    .right_section{
        justify-content: flex-end;
    }
    .section{
        scroll-snap-align: center;
        display: flex;
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .dimm{
            background-color: rgba(0, 0, 0, 0.75);
            height: 100vh;
            width: 65vw;
            color: white;
            @media screen and (max-width: 900px) {
                width: 100vw;
                justify-content: center;
            }
            h1{
                padding: 7vw 25vw 0 5vw;
                font-size: 9vh;
                margin-bottom: 10vh;
                @media screen and (max-width: 900px) {
                    
                    padding: 40vw 5vw 12vh 5vw;
                    margin: 0 0 5vh 0;
                    font-size: 7vh;
                }
            }
            a{
                @media screen and (max-width: 1130px) {
                    margin-bottom: 0;
                }
            }
            p{
                padding: 2vw 2vw 0 5vw;
                font-size: 4vh;
                @media screen and (max-width: 1130px) {
                    padding: 0 3vw 2vw 3vw;
                    margin: 0 auto 0 auto;
                    font-size: 3.2vh;
                } 
            }
            .logos{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: auto;
                img{
                    width: 7vw;
                    margin: 0.1vw;
                    @media screen and (max-width: 1130px) {
                        padding: 1vh 0 0 0;
                        width: 20vw;
                        height: 20vw;
                    } 
                }
            }
            .opis{
                padding: 7vw 25vw 0 5vw;
                font-size: 9vh;
                height: 9vh;
                @media screen and (max-width: 1130px) {
                    flex-basis:100%;
                    padding: 12vh 0 5vh 2vw;
                    margin-bottom: 0;
                    font-size: 7vh;
                }
            }
        }
        .contact{
            display: flex;
            flex-wrap: wrap;
            padding-top: 10vh;
            width: 100vw;
            height: 80vh;
            @media screen and (max-width: 1130px) {
            justify-content: center;
            align-items: center;
            }
            iframe{
                width: 60vw;
                max-width: 50%;
                height: 80vh;
                @media screen and (max-width: 1130px) {
                width: 100vw;
                max-width: 100%;
                height: 20vh;

                }
            }
            .formularz{
                height: 30vh;
                width: 48vw;
                max-width: 48%;
                margin-left: 2%;
                @media screen and (max-width: 1130px) {
                    width: 90vw;
                    max-width: 90%;
                    height: 60vh;
                    margin: 0;
    
                    }
                h1{
                    font-size: 5vh;
                }
                #name, #email{
                    border: none;
                    border-bottom: 0.1vh solid $primary;
                    width: 30vw;
                    height: 5vh;
                    font-size: 2vh;
                    margin-bottom: 1vh;
                    @media screen and (max-width: 1130px) {
                    width: 90vw;
                    }
                }
                #comment{
                    width: 30vw;
                    height: 10vh;
                    margin-bottom: 1vh;
                    font-size: 2vh;
                    border: none;
                    border-bottom: 0.1vh solid $primary;
                    font-family: 'Roboto', sans-serif;
                    @media screen and (max-width: 1130px) {
                        width: 90vw;
                    }
                }
                #submit{
                    align-items: center;
                    border: none;
                    width: 15vw;
                    height: 4vh;
                    font-size: 2vh;
                    color: $primary;
                    border: 0.1vh solid $primary;
                    cursor: pointer;
                    @media screen and (max-width: 1130px) {
                        width: 90vw;
                    }
                }
            }
            .kontakt_info{
                margin-top: 5vh;
                width: 50vw;
                max-width: 50%;
                font-size: 3vh;
                @media screen and (max-width: 1130px) {
                    margin-top: 1vh;
                    width: 100vw;
                    height: 20vh;
                    max-width: 100%;
                    font-size: 1.5vh;
                    }
            }
        }
    }
    .jeden{
        background-image: url(/src/img/sections/1.webp);
        a{
            text-decoration: none;
            button{
                width: 26vw;
                height: 12vh;
                color: white;
                border: solid .5vh black;
                background-color: rgba(0, 0, 0, 0.5);
                font-size: 4vh;
                @media screen and (max-width: 1130px) {
                    width: 40vw;
                }
                @media screen and (max-width: 900px) {
                    width: 65vw;
                }
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: .5s;
                margin-left: auto;
                margin-right: auto;
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
    }
    .dwa{
        background-image: url(/src/img/sections/2.webp);
    }
    .trzy{
        background-image: url(/src/img/sections/3.webp);
    }
    .cztery{
        background-image: url(/src/img/sections/4.webp);
    }
    .piec{
        display: flex;
        flex-wrap: wrap;
    }
    footer{
        display: flex;
        background-color: $primary;
        height: 10vh;
        width: 100vw;
        max-width: 100%;
        display: flex;
        align-items: center;
        img{
            margin-left: 1vw;
            height: 3vh;
            width: auto;
            align-items: flex-start;
        }
    }
}
}
//galeria
.galeria_bramy{
    padding: 0;
    margin-top: 11vh;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.thumbnail:hover{
    cursor: pointer ;
}
.thumbnail{
    margin: 1vw;
    padding: 0;
    list-style: none;
    img{
        width: 28vw;
        max-width: 100vw;
        height: 25vw;
        object-fit: cover;
        transition: .2s;
    }
    img:hover{
        transition: .2s;
        transform: scale(1.1);
    }
    @media screen and (max-width: 1130px) {
        margin: 2vw;
        padding: 0;
        list-style: none;
        img{
            width: 90vw;
            height: 45vw;
            object-fit: cover;
        }
    }
}
.popup__close{
    right: 2vw;
    top: 2vw;
    position: fixed;
    background-color: unset;
    color: white;
    border: unset;
    cursor: pointer;
    font-size: 2rem;
    z-index: 1;
    img{
        width: 2vw;
        @media only screen and (max-width: 900px) {
            width: 5vw;
        }
    }
}
.popup__close:hover{
    transform: scale(1.2);
}
.popup.hidden{
    display: none ;
}
.popup{
    z-index: 3;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    .popup__dark{
        background-color: rgba(0, 0, 0, 0.9);
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
    }
    .popup__img{
        z-index: 0;
        object-fit: unset;
        width: auto;
        height: 80vh;
    }
    .popup__arrow{
        height: 100vh;
        border: unset;
        position: fixed;
        background-color: unset;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img{
            width: 5vw;
            @media only screen and (max-width: 900px) {
                width: 10vw;
            }
        }
    }
    .popup__arrow--left{
        left: 5vw;
    }
    .popup__arrow--right{
        right: 5vw;
    }
    .popup__arrow:active{
        transform: scale(0.9);
    }
    .popup__arrow--left{
        left: 5vw;
    }
    .popup__arrow--right{
        right: 5vw;
    }
    @media screen and (max-width: 1130px) {
        .popup__img{
            max-width: 90vw;
            max-height: 90vh;
            height: auto;
        }
    }
}
//half-desktop
@media screen and (max-width: 1130px) {
    .hamburger, .navigation{
        transition: transform .3s .1s ease-in-out;
    }
    .hamburger--active{
        transition: translate(40vw);
    }
    .hamburger--active .hamburger__inner{
        background-color: transparent;
    }
    .hamburger--active .hamburger__inner::before{
        transform: translateY(10px) rotate(45deg);
    }
    .hamburger--active .hamburger__inner::after{
        transform: translateY(-10px) rotate(-45deg);
    }
    .hamburger__box{
        z-index:1;
        width: 4vw;
        height: 1vh;
        display: inline-block;
        position: relative;
    }
    @mixin hamburger-line{
        width: 100%;
        height: 0.2vh;
        background-color: white;
        position: absolute;
    }
    .hamburger__inner{
        @include hamburger-line;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: background-color .1s .2s ease-in-out;
    }
    .hamburger__inner::before,
    .hamburger__inner::after{
        @include hamburger-line;
    
        content: '';
        left: 0;
        transition: transform .2s .2s ease-in-out;
    }
    .hamburger__inner::after{
        top: 10px;
    }
    .hamburger__inner::before{
        top: -10px;
    }
    .navigation{
        padding: 10vh 0 0 0;
        height: 100%;
        width: 50vw;
        background-color: #1d4275;
        position: fixed;
        top: 0;
        left: 0;
        transform: translateX(-50vw);
    }
    .navigation--active{
        transform: translateX(0px);
    
    }
    .navigation__list{
        flex-wrap: wrap;
        padding: 1vw;
    }
    .navigation__item{
        font-size: 2em;
        width: 100%;
        margin: 0 0 2vh 1vw;
    }
}

@media only screen and (max-width: 900px) {
    .hamburger__box{
        width: 7vw;
    }
    .navigation__item{
        font-size: 1.5em;
    }
    .navigation{
        width: 65vw;
        transform: translateX(-65vw);
    }
    .navigation--active{
        transform: translateX(0px);
    }
}